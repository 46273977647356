<template>
  <div
    :key="dataset.uiKey"
    width="100%"
    style="height: 1200px"
  >
    <v-dialog
      v-model="dialog"
      style="z-index: 1000"
      width="80%"
    >
      <v-card>
        <v-card-title>
          <!-- Word Cloud -->
          <v-spacer />
          <v-icon @click="dialog = false">
            close
          </v-icon>
        </v-card-title>

        <v-card-text style="height: calc(80vh)">
          <wordcloud-chart style="width: 100%" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <h1>{{ dataset.uiKey }}</h1> -->
    <!-- <predict-chart
      width="100%"
      v-if="events"
      style="height: 500px"
      :dataset="dataset"
      :model="model"
      :location="location"
      :events="events"
    /> -->
    <predict-band-chart
      v-if="events && model && dataset"
      width="100%"
      style="height: 400px"
      :dataset="dataset"
      :model="model"
      :location="location"
      :events="events"
    />
    <calibrate-chart
      v-if="location"
      width="100%"
      style="height: 500px"
      :dataset="dataset"
      :model="model"
      :location="location"
      @calibrated="calibrated()"
    />
  </div>
</template>
<script>
import series from './data/series';

// console.log('series.monthDataSeries1.prices', series.monthDataSeries1.prices);
// console.log('series.monthDataSeries1.dates', series.monthDataSeries1.dates);

import { createDatasetQuery } from '../../lib/query-creator';
import ApiService from '../../services/api.service';
import { transformToTimeSeries } from './lib/transformer';

// const VueApexCharts = () => import('vue-apexcharts');
const WordcloudChart = () => import('@/components/chart/wordcloud.vue');
const CalibrateChart = () => import('@/components/chart/calibrate/chart.vue');
// const PredictChart = () => import('@/components/chart/predict/chart.vue');
const PredictBandChart = () => import('@/components/chart/predict-band/chart.vue');

export default {
  components: {
    // apexchart: VueApexCharts,
    WordcloudChart,
    CalibrateChart,
    // PredictChart,
    PredictBandChart,
  },
  props: ['AnnotatedChart', 'dataset', 'model', 'location'],
  data() {
    return {
      dialog: false,
      options: null,
      series: null,
      series1: [
        {
          data: series.monthDataSeries1.prices,
        },
      ],
      events: null,
      predictChart: null,
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          id: 'areachart-2',
        },
        annotations: {
          yaxis: [
            {
              y: 8200,
              borderColor: '#00E396',
              label: {
                borderColor: '#00E396',
                style: {
                  color: '#fff',
                  background: '#00E396',
                },
                text: 'Support',
              },
            },
            {
              y: 8600,
              y2: 9000,
              borderColor: '#000',
              fillColor: '#FEB019',
              opacity: 0.2,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#FEB019',
                },
                text: 'Y-axis range',
              },
            },
          ],
          xaxis: [
            {
              x: new Date('23 Nov 2017').getTime(),
              strokeDashArray: 0,
              borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                text: 'Anno Test',
              },
            },
            {
              x: new Date('26 Nov 2017').getTime(),
              x2: new Date('28 Nov 2017').getTime(),
              fillColor: '#B3F7CA',
              opacity: 0.4,
              label: {
                borderColor: '#B3F7CA',
                style: {
                  fontSize: '10px',
                  color: '#fff',
                  background: '#00E396',
                },
                offsetY: -10,
                text: 'X-axis range',
              },
            },
          ],
          points: [
            {
              x: new Date('01 Dec 2017').getTime(),
              y: 8607.55,
              marker: {
                size: 8,
                fillColor: '#fff',
                strokeColor: 'red',
                radius: 2,
                cssClass: 'apexcharts-custom-class',
              },
              label: {
                borderColor: '#FF4560',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#FF4560',
                },

                text: 'Point Annotation',
              },
            },
            // {
            //   x: new Date('08 Dec 2017').getTime(),
            //   y: 9340.85,
            //   marker: {
            //     size: 0,
            //   },
            //   image: {
            //     path: '../../assets/images/ico-instagram.png',
            //   },
            // },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          padding: {
            right: 30,
            left: 20,
          },
        },
        title: {
          text: 'Line with explanations',
          align: 'left',
        },
        labels: series.monthDataSeries1.dates,
        xaxis: {
          type: 'datetime',
        },
      },
    };
  },
  mounted() {
    const that = this;
    this.loadData();
    this.events = {
      setPredictChart: (chart) => {
        that.predictChart = chart;
      },
    };
    // alert(this.dataset.uiKey);
  },
  methods: {
    async calibrated() {
      const model = this.model;
      const independent_variable_names_selected_by_user = this.dataset.independent_variable_names_selected_by_user;
      const payload = {
        model_code: model.model_code,
        independent_variable_names_selected_by_user,
      };

      this.$store.commit('mSetSubLoading', true);
      const response = await ApiService.post('/update_predictions/', payload);
      this.$store.commit('mSetSubLoading', false);
      this.predictChart.loadData();
      // alert('calibrated');
    },
    openDialog() {
      this.dialog = true;
      // alert("openDialog " + this.dialog);
    },
    clickHandler(event, chartContext, config) {
      // event, chartContext, config
      console.log('click');
      // console.log(chartContext)
      // console.log(config)
      // const that = this;
      console.log(event);
      console.log(chartContext);
      console.log(config);
      // console.log(event);
      const timeout = setTimeout(() => {
        // that.openDialog();
        clearTimeout(timeout);
      }, 100);
    },
    async loadData() {
      this.$store.commit('mSetLoading', true);
      if (this.dataset && this.dataset.table_name) {
        const appConfig = this.$store.state.appConfig;
        console.log('this.dataset', this.dataset);
        const q = createDatasetQuery(this.dataset);
        const response = await ApiService.post('/raw_sql/', { q });

        const data = response.data;

        const { options, series } = transformToTimeSeries(
          data,
          this.dataset,
          appConfig,
        );

        this.options = options;
        this.series = series;

        console.log('sql', q);
        console.log('data', data);
      }
      this.$store.commit('mSetLoading', false);
    },
  },
};
</script>
